<template>
  <div class="Message xi">
    <nh-com></nh-com>
    <div class="banner">
      <div class="banner-wrap clearfix">
        <div class="banner-text">
          <h3>个人中心</h3>
          <p>当前位置： <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>测试记录</a></p>
        </div>
      </div>
    </div>
    <div class="personal-content">
      <div class="personal-box clearfix">
        <div class="personal-left">
          <hd-img></hd-img>
        </div>
        <div class="personal-right">
          <div class="personal-right-tit">
            <i class="iconfont">&#xe62c;</i><span>测试<em>记录</em></span>
            <ul class="tab-tit">
              <li v-for="(title, index) in tabTitle" @click="(cur = title.name), tabClick(title)" :class="{ active: title.name == cur }">{{ title.name }}</li>
            </ul>
            <div class="flex-end" style="margin-top: 10px">
              <!-- <el-input v-if="cur == 2" v-model="keyWord" style="width: 60%" :placeholder="placeholderStr" clearable @keyup.enter.native="search">
                <el-button slot="append" class="search-btn" type="button" icon="el-icon-search" @click="search"> </el-button>
              </el-input> -->
              <el-input placeholder="请输入内容" style="width: 60%" v-model="keyWord" class="input-with-select" @keyup.enter.native="search" clearable>
                <el-select v-model="selectType" slot="prepend" placeholder="请选择" style="width: 120px">
                  <el-option label="用户信息" value="1"></el-option>
                  <el-option label="基础病" v-if="cur != '辨病论治'" value="2"></el-option>
                  <el-option label="结果名称" value="3" v-if="cur != '辨证论治' && cur != '辨病论治'"></el-option>
                  <el-option label="主症名称" value="3" v-if="cur == '辨证论治'  && cur != '辨病论治'"></el-option>
                </el-select>
                <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
              </el-input>
            </div>
          </div>
          <!--    内容    -->
          <div v-if="cur != '辨证论治' && cur != '辨病论治'" class="center-content">
            <ul class="tab-tit tab-titBZ">
              <li @click="goMyClickTZ(1)" :class="TZIndex == 1 ? 'tableCurSelect': '' ">我的记录</li>
              <li v-if="paramsHard.length > 0" @click="goMyClickTZ(2)" :class="TZIndex == 2 ? 'tableCurSelect': '' ">大屏记录</li>
            </ul>
            <el-table :data="tableData" style="width: 100%" :show-header="true" v-if="tableData.length>0">
              <!-- <el-table-column prop="questionnaire.name" label="测试类型" sortable> </el-table-column> -->
              <el-table-column prop="customerName" label="测试对象">
                <template slot-scope="data">
                  <p>{{ data.row.customerName }}</p>
                  <p>{{ data.row.customerPhone }}</p>
                </template>
              </el-table-column>
              <el-table-column prop="diseaseName" label="基础病" sortable align="center">
                <template slot-scope="data">
                  <p>{{ data.row.diseaseName ? data.row.diseaseName : "无" }}</p>
                </template>
              </el-table-column>
                <el-table-column prop="customerAgeDesc" label="测试结果" align="center">
                  <template slot-scope="data">
                    <p v-if="data.row.questionnaireDetermines&&data.row.questionnaireDetermines.length > 0">{{ data.row.questionnaireDetermines[0].name }}</p>
                    <p v-else>无结果</p>
                  </template>
                </el-table-column>
                <el-table-column sortable prop="time" label="测试时间" align="center">
                  <template slot-scope="data">
                    {{ data.row.time | timefilters }}
                  </template>
                </el-table-column>
              <el-table-column fixed="right" label="操作" width="100" align="center">
                <template slot-scope="scope">
                  <el-button size="mini" type="primary" plain @click="toDetail(scope.row)">详情</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination v-if="tableData.length>0" @current-change="ChangePage" :page-size="15" :current-page="PageIndex" :total="Total" layout="total, prev, pager, next, jumper"></el-pagination>
          </div>
          <!-- 中医问诊记录 -->
          <div v-if="cur == '辨证论治'">
            <ul class="tab-tit tab-titBZ">
              <li @click="goMyClickBZ(1)" :class="BZIndex == 1 ? 'tableCurSelect': '' ">我的记录</li>
              <li v-if="paramsHard.length > 0" @click="goMyClickBZ(2)" :class="BZIndex == 2 ? 'tableCurSelect': '' ">大屏记录</li>
            </ul>
            <div class="drugBox" v-for="(itemDrug, indexDrug) in tableData" :key="indexDrug">
              <div class="drugBoxtopBox">
                <div class="drugBoxtop">
                  <div class="ring"></div>
                  <div style="margin-left: 5px">{{ itemDrug.userName }} - {{ itemDrug.userPhone ? itemDrug.userPhone.substr(0, 3) + "****" + itemDrug.userPhone.substr(7) : "" }}</div>
                  <div style="margin-left: 10px">
                    {{ itemDrug.gender ? "女" : "男" }}
                  </div>
                  <div style="margin-left: 15px">
                    {{ itemDrug.ageName }}
                  </div>
                  <!-- <div style="font-size: 16px; margin-left: 15px">
                    您选择的主症是：<span style="color: #d38539">{{ itemDrug.primarySymptomName }}</span>
                  </div> -->
                </div>
                <div style="font-size: 18px; color: #d38539; cursor: pointer" @click="goresultB(itemDrug)">查看详情<i class="el-icon-arrow-right"></i></div>
              </div>
              <div style="font-size: 16px; margin-top: 0px; margin-left: 30px">
                <p>您的基础病：{{ itemDrug.answerDiseaseName ? itemDrug.answerDiseaseName : "暂未填写" }}</p>
              </div>
              <div style="font-size: 16px; margin-top: 15px">
                <p>您选择的主症是：{{ itemDrug.primarySymptomName }}</p>
                <p>您的症状清单：</p>

                <div class="flexlr">
                  <div v-for="(itemsym, indexsym) in itemDrug.symptoms" :key="indexsym">
                    {{ "【" + itemsym + "】" }}
                  </div>
                </div>
              </div>
            </div>
            <el-pagination v-if="tableData.length>0" style="padding-left: 30px;" @current-change="ChangePage1" :page-size="15" :current-page="PageIndex" :total="Total" layout="total, prev, pager, next, jumper"></el-pagination>
          </div>
          <div v-if="cur == '辨病论治'">
            <div class="drugBox" v-for="(itemDrug, indexDrug) in tableData" :key="indexDrug">
              <div class="drugBoxtopBox">
                <div class="drugBoxtop">
                  <div class="ring"></div>
                  <div style="margin-left: 5px">{{ itemDrug.userName }} - {{ itemDrug.userPhone ? itemDrug.userPhone.substr(0, 3) + "****" + itemDrug.userPhone.substr(7) : "" }}</div>
                  <div style="margin-left: 10px">
                    {{ itemDrug.gender ? "女" : "男" }}
                  </div>
                  <div style="margin-left: 15px">
                    {{ itemDrug.ageName }}
                  </div>
                  <!-- <div style="font-size: 16px; margin-left: 15px">
                    您选择的主症是：<span style="color: #d38539">{{ itemDrug.primarySymptomName }}</span>
                  </div> -->
                </div>
                <div style="font-size: 18px; color: #d38539; cursor: pointer" @click="goresultB(itemDrug)">查看详情<i class="el-icon-arrow-right"></i></div>
              </div>
              <div style="font-size: 16px; margin-top: 0px; margin-left: 30px">
                <p>您的基础病：{{ itemDrug.answerDiseaseName ? itemDrug.answerDiseaseName : "暂未填写" }}</p>
              </div>
              <div style="font-size: 16px; margin-top: 15px">
                <div class="flexlr">
                  <p>{{ itemDrug.primarySymptomName }}</p>
                  <p style="margin-left:10px" v-if="itemDrug.categoryFullName">部门:{{ itemDrug.categoryFullName }}</p>
                  <p style="margin-left:10px" v-if="itemDrug.primarySymptomAlias">别名:{{ itemDrug.primarySymptomAlias }}</p>
                </div>
                
                <p>您的症状清单：</p>

                <div class="flexlr">
                  <div v-for="(itemsym, indexsym) in itemDrug.symptoms" :key="indexsym">
                    {{ "【" + itemsym + "】" }}
                  </div>
                </div>
              </div>
            </div>
            <el-pagination v-if="tableData.length>0" style="padding-left: 30px;" @current-change="ChangePage2" :page-size="15" :current-page="PageIndex" :total="Total" layout="total, prev, pager, next, jumper"></el-pagination>
          </div>
            <el-empty v-if="tableData.length==0" :image-size="200"></el-empty>
        </div>
      </div>
    </div>
    <ft-com></ft-com>
  </div>
</template>

<script>
import ftCom from "../../components/footer";
import nhCom from "../../components/navheader";
import hdImg from "../../components/headerImg";
import { Test } from "../../components/company/test";
import { zdrugconsultationMain } from "../../components/zdrugconsultation/zdrugconsultation";
export default {
  name: "Collect",
  components: {
    ftCom,
    nhCom,
    hdImg,
  },
  data() {
    var test = new Test(this.TokenClient, this.Services.Statics);
    var zdrugconsultation = new zdrugconsultationMain(this.TokenClient, this.Services.Disease);
    var zAuthorizationconsultation = new zdrugconsultationMain(this.TokenClient, this.Services.Authorization);
    return {
      zdrugconsultationMain: zdrugconsultation,
      zAuthorizationconsultationMain: zAuthorizationconsultation,
      testDomain: test,
      userInfo: this.$store.state.localData.userInfo,
      labelPosition: "right",
      placeholderStr: "请输入姓名或手机号",
      tabTitle: [],
      tableData: [
        {
          questionnaireDetermines: [
            {
              name: "",
            },
          ],
        },
      ],
      false: false,
      keyWord: "",
      cur: '中医体质辨识', //默认选中第一个tab
      results: [],
      selectData: [],
      PageIndex: 1,
      PageTotal: 1,
      Total: 0,
      type: "0",
      list: [],
      UserSelfDiagnosisRecordList: [],
      selectType: "1",
      qId: 0,
      BZIndex:1,
      TZIndex:1,
      paramsHard:[]
    };
  },
  mounted() {
    if (this.$route.query.type == 2) {
      this.cur = 2;
      this.getUserSelfDiagnosisRecordList();
      this.getHardwareByUserData()
    } else if (this.$route.query.type == 3) {
      this.cur = 3;
      this.getTCUserSelfDiagnosisRecordList();
      this.getHardwareByUserData()
    } else {
      this.getHardwareByUserData()
      this.getQuestionnaire();
    }
  },
  methods: {
    goMyClickTZ(e){
      this.TZIndex = e
      if (this.TZIndex == 1) {
        this.init()
      } else {
        this.initHardware()
      }
    },
    goMyClickBZ(e){
      this.BZIndex = e
      if (this.BZIndex == 1) {
        this.getUserSelfDiagnosisRecordList()
      } else {
        this.getSelfDiagnosisHardwareData()
      }
    },
    getQuestionnaire() {
      this.testDomain.Questionnaire(
        res => {
          this.tabTitle = [];
          res.data.forEach(element => {
            if (element.name.includes("体质")) {
              this.tabTitle.push({
                id: element.id,
                name: "中医体质辨识",
              });
            } else if (element.name.includes("虚证")) {
              this.tabTitle.push({
                id: element.id,
                name: "中医虚证辨证",
              });
            }
          });
          this.tabTitle.push({ id: "-1", name: "辨证论治" }, { id: "-3", name: "辨病论治" });
          // this.tabTitle.push({ id: "-1", name: "辨证论治" },{ id: "-2", name: "用户测试" });
          // this.tabTitle.push({ id: "-1", name: "辨证论治" });
          this.qId = this.tabTitle[0].id;
          this.init();
        },
        error => {
          console.log("请求失败!请刷新重试:", error);
        }
      );
    },
    search() {
      this.PageIndex = 1;
      this.tableData = [];
      if (this.cur == "中医体质辨识") {
        this.init(); 
        this.getHardwareByUserData()
      }
      else if (this.cur  == "中医虚证辨证") {
        this.init();
        this.getHardwareByUserData()
      }
      else if (this.cur == "辨证论治") {
        this.getUserSelfDiagnosisRecordList();
        this.getHardwareByUserData()
      }  
       else if (this.cur == "辨病论治") {
        this.getTCUserSelfDiagnosisRecordList();
      }else {
        this.getCustomerResult();
      }
    },
    goresult(e) {
      this.$router.push({
        path: "/zdrugconsultation/zdrugDetail",
        query: {
          id: e.id,
        },
      });
    },
    goresultB(e) {
      this.$router.push({
        path: "/diseaseConsultation/diseaseDetail",
        query: {
          id: e.id,
        },
      });
    },
    getTCUserSelfDiagnosisRecordList(){
       var _this = this;
      let keyWord = "";
      _this.zdrugconsultationMain.getUserTCMDiseaseRecord(
        "",
        "",
        keyWord,
        _this.PageIndex,
        function (data) {
          _this.PageIndex = data.data.pageIndex;
          _this.PageTotal = data.data.pageCount;
          _this.Total = data.data.dataTotal;
          for (let i = 0; i < data.data.results.length; i++) {
            if (data.data.results[i].age == 0) {
              data.data.results[i].ageName = "6岁以下";
            } else if (data.data.results[i].age == 7) {
              data.data.results[i].ageName = "7-12岁";
            } else if (data.data.results[i].age == 13) {
              data.data.results[i].ageName = "13-17岁";
            } else if (data.data.results[i].age == 18) {
              data.data.results[i].ageName = "18-45岁";
            } else if (data.data.results[i].age == 46) {
              data.data.results[i].ageName = "46-69岁";
            } else if (data.data.results[i].age == -1) {
              data.data.results[i].ageName = data.data.results[i].primarySymptomMinAge + "-" + data.data.results[i].primarySymptomMaxAge + "岁";
            } else {
              data.data.results[i].ageName = "69岁以上";
            }
            data.data.results[i].diagnosisTime = data.data.results[i].diagnosisTime.slice(0, 10);
          }
          _this.tableData = data.data.results;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    getUserSelfDiagnosisRecordList() {
      var _this = this;
      let keyWord = "";
      let diseaseName = "";
      let resultName = "";
      switch (this.selectType) {
        case "1":
          keyWord = this.keyWord;
          break;
        case "2":
          diseaseName = this.keyWord;
          break;
        case "3":
          resultName = this.keyWord;
          break;
      }
      _this.zdrugconsultationMain.getUserSelfDiagnosisRecord(
        "",
        "",
        keyWord,
        _this.PageIndex,
        diseaseName,
        resultName,
        function (data) {
          _this.PageIndex = data.data.pageIndex;
          _this.PageTotal = data.data.pageCount;
          _this.Total = data.data.dataTotal;
          for (let i = 0; i < data.data.results.length; i++) {
            if (data.data.results[i].age == 0) {
              data.data.results[i].ageName = "6岁以下";
            } else if (data.data.results[i].age == 7) {
              data.data.results[i].ageName = "7-12岁";
            } else if (data.data.results[i].age == 13) {
              data.data.results[i].ageName = "13-17岁";
            } else if (data.data.results[i].age == 18) {
              data.data.results[i].ageName = "18-45岁";
            } else if (data.data.results[i].age == 46) {
              data.data.results[i].ageName = "46-69岁";
            } else if (data.data.results[i].age == -1) {
              data.data.results[i].ageName = data.data.results[i].primarySymptomMinAge + "-" + data.data.results[i].primarySymptomMaxAge + "岁";
            } else {
              data.data.results[i].ageName = "69岁以上";
            }
            data.data.results[i].diagnosisTime = data.data.results[i].diagnosisTime.slice(0, 10);
          }
          _this.tableData = data.data.results;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    init() {
      var _this = this;
      let keyWord = "";
      let diseaseName = "";
      let resultName = "";
      switch (this.selectType) {
        case "1":
          keyWord = this.keyWord;
          break;
        case "2":
          diseaseName = this.keyWord;
          break;
        case "3":
          resultName = this.keyWord;
          break;
      }
      _this.testDomain.getUserResultByUser(
        _this.userInfo.id,
        keyWord,
        _this.PageIndex,
        diseaseName,
        resultName,
        _this.qId,
        "",
        function (data) {
          _this.tableData = data.data.results;
          _this.tableData.map(item => {
            item.questionnaireDetermines.sort((a, b) => {
              return b.scores - a.scores;
            });
          });
          _this.PageIndex = data.data.pageIndex;
          _this.PageTotal = data.data.pageCount;
          _this.Total = data.data.dataTotal;
        },
        function (err) {
          console.log(err);
        }
      );
    },
    
    getHardwareByUserData() {
      var _this = this;
      _this.zAuthorizationconsultationMain.getHardwareByUser(
        _this.userInfo.id,
        function (data) {
          _this.paramsHard = []
					for (let i=0; i<data.data.length; i++) {
						_this.paramsHard.push(data.data[i].hardware.sn)
					}
        },
        function (err) {
          console.log(err)
          _this.paramsHard = []
        }
      );
    },
    getSelfDiagnosisHardwareData(){
      var _this = this
      let keyWord = "";
      let diseaseName = "";
      let resultName = "";
      switch (this.selectType) {
        case "1":
          keyWord = this.keyWord;
          break;
        case "2":
          diseaseName = this.keyWord;
          break;
        case "3":
          resultName = this.keyWord;
          break;
      }
      _this.zdrugconsultationMain.getSelfDiagnosisHardware(
        "",
        "",
        keyWord,
        _this.PageIndex,
        diseaseName,
        resultName,
        _this.paramsHard,
        function (data) {
          _this.PageIndex = data.data.pageIndex;
          _this.PageTotal = data.data.pageCount;
          _this.Total = data.data.dataTotal;
          for (let i = 0; i < data.data.results.length; i++) {
            if (data.data.results[i].age == 0) {
              data.data.results[i].ageName = "6岁以下";
            } else if (data.data.results[i].age == 7) {
              data.data.results[i].ageName = "7-12岁";
            } else if (data.data.results[i].age == 13) {
              data.data.results[i].ageName = "13-17岁";
            } else if (data.data.results[i].age == 18) {
              data.data.results[i].ageName = "18-45岁";
            } else if (data.data.results[i].age == 46) {
              data.data.results[i].ageName = "46-69岁";
            } else if (data.data.results[i].age == -1) {
              data.data.results[i].ageName = data.data.results[i].primarySymptomMinAge + "-" + data.data.results[i].primarySymptomMaxAge + "岁";
            } else {
              data.data.results[i].ageName = "69岁以上";
            }
            data.data.results[i].diagnosisTime = data.data.results[i].diagnosisTime.slice(0, 10);
          }
          _this.tableData = data.data.results;
        },
        function (err) {
          console.log(err)
          _this.paramsHard = []
        }
      );
    },
    initHardware() {
      var _this = this;
      let keyWord = "";
      let diseaseName = "";
      let resultName = "";
      switch (this.selectType) {
        case "1":
          keyWord = this.keyWord;
          break;
        case "2":
          diseaseName = this.keyWord;
          break;
        case "3":
          resultName = this.keyWord;
          break;
      }
      _this.testDomain.getSelfDiagnosisHardware(
        keyWord,
        _this.PageIndex,
        diseaseName,
        resultName,
        _this.qId,
        _this.paramsHard,
        function (data) {
          _this.tableData = data.data.results;
          _this.tableData.map(item => {
            item.questionnaireDetermines.sort((a, b) => {
              return b.scores - a.scores;
            });
          });
          _this.PageIndex = data.data.pageIndex;
          _this.PageTotal = data.data.pageCount;
          _this.Total = data.data.dataTotal;
        },
        function (err) {
          console.log(err);
        }
      );
    },
    getCustomerResult() {
      var _this = this;
      _this.testDomain.getCustomerResult(
        _this.userInfo.id,
        _this.keyWord,
        _this.PageIndex,
        function (data) {
          _this.tableData = data.data.results;
          _this.tableData.map(item => {
            item.questionnaireDetermines.sort((a, b) => {
              return b.scores - a.scores;
            });
          });
          _this.PageIndex = data.data.pageIndex;
          _this.PageTotal = data.data.pageCount;
          _this.Total = data.data.dataTotal;
        },
        function (err) {
          console.log(err);
        }
      );
    },

    ChangePage(pageIndex) {
      this.PageIndex = pageIndex;
      this.init();
    },
     ChangePage1(pageIndex) {
      this.PageIndex = pageIndex;
      this.getUserSelfDiagnosisRecordList();
    },
    ChangePage2(pageIndex) {
      this.PageIndex = pageIndex;
      this.getTCUserSelfDiagnosisRecordList();
    },
    tabClick: function (item) {
      console.log(item);
      this.PageIndex = 1;
      this.BZIndex = 1;
      this.TZIndex = 1;
      this.tableData = [];
      this.Total=0
      this.keyWord = "";
      if (item.name == "中医体质辨识") {
        this.qId = item.id;
        this.init();
        this.getHardwareByUserData()
      } else if (item.name == "中医虚证辨证") {
        this.qId = item.id;
        this.init();
        this.getHardwareByUserData()
      } else if (item.name == "辨证论治") {
        this.getUserSelfDiagnosisRecordList();
        this.getHardwareByUserData()
      }  
       else if (item.name == "辨病论治") {
        this.getTCUserSelfDiagnosisRecordList();
      }else {
        this.getCustomerResult();
      }
    },
    /**
     * 获取详情
     */
    toDetail(row) {
      const href = this.$router.resolve({
        path: "/company/testResult",
        query: {
          resId: row.id,
          answerUserId: row.userId,
        },
      });
      window.open(href.href, "_blank");
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/footer.css";
.tab-titBZ {
  margin-top: 20px;
}
.banner {
  width: 100%;
  height: 160px;
  background: url("../../assets/img/news_banner.jpg") center no-repeat;
}

.banner-wrap {
  position: relative;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.banner-text {
  float: left;
}

.banner-text h3 {
  padding-top: 35px;
  font-size: 26px;
  font-weight: normal;
  color: #ffffff;
}

.banner-text p {
  font-size: 14px;
  color: #ffffff;
  margin-top: 20px;
}

.banner-text p a {
  color: #ffffff;
  font-size: 14px;
  margin: 0 5px;
}

/**/
.personal-content {
  background: #f7f7f7;
  padding: 40px 0;
}

.personal-box {
  width: 1200px;
  margin: 0 auto;
}

.personal-left {
  float: left;
}

.personal-right {
  float: right;
  width: 900px;
  min-height: 740px;
  background: #ffffff;
  box-sizing: border-box;
}

.personal-right-tit {
  border-bottom: 1px solid #e5e5e5;
  padding: 20px;
}

.personal-right-tit .iconfont {
  color: #04a290;
  font-size: 24px;
  margin-right: 5px;
}

.personal-right-tit span {
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
  color: #333333;
}

.personal-right-tit span em {
  font-style: normal;
  color: #04a290;
}

.personal-right-tit .el-icon-delete {
  float: right;
  font-size: 20px;
  margin-top: 10px;
}

.tab-tit {
  margin-left: 40px;
  display: inline-block;
}

.tab-tit li {
  font-size: 16px;
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  font-weight: bold;
}

.tab-tit li.active {
  color: #1fb7a6;
}
::v-deep .el-pagination {
  margin-top: 10px;
}
.center-content {
  padding: 0 20px;
}

.flexlr {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
<style>
.center-content .el-table .cell {
  cursor: pointer !important;
}

.el-table::before {
  height: 0;
}

.el-table td,
.el-table th.is-leaf {
  border-bottom: 1px dashed #ebeef5;
}

.ring {
  width: 12px;
  height: 12px;
  border: 3px solid;
  box-shadow: 0px 2px 3px 0px rgba(202, 127, 55, 0.3);
  border-radius: 50%;
  margin-right: 5px;
}

.drugBox {
  padding: 20px 35px;
  border-bottom: 1px solid #e6e6e6;
}

.drugBoxtop {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #333;
  font-weight: bold;
}

.drugBoxtopBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tableCurSelect {
  color: #04a290;
}
</style>
